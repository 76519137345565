import React, { useEffect, useState } from "react";
import logo from "../../assets/xstore_logo.png";
import styles from "./index.module.scss";
import { Link, useLocation } from "react-router-dom";
import MenuBar from "../../shared/Menubar";

const DesktopNav = () => {
  const { pathname } = useLocation();
  const [scrollY, setScrollY] = useState(0);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setToggle(false);
  }, [pathname]);

  const onOpenMenu = (e) => {
    setToggle(!toggle);
  };

  return (
    <div className={scrollY > 50 ? styles.newNav : styles.navCont}>
      <nav className={styles.nav}>
        <div className={styles.nav__content}>
          <div className={styles.nav__content__logo}>
            <Link to="/">
              <img src={logo} alt="logo" className={styles.nav__content__logo__img} />
            </Link>
          </div>
          <div className={styles.nav__content__menu}>
            <ul>
              <li>
                <Link to="/" className={pathname === "/" ? styles.active : ""}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about-us" className={pathname === "/about-us" ? styles.active : ""}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/pricing" className={pathname === "/pricing" ? styles.active : ""}>
                  Pricing
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.nav__content__signin}>
            <ul>
              <li>
                <Link to="/signin">Signin</Link>
              </li>
              <li>
                <button>
                  <Link to="/try-it-free">Try It Free</Link>
                </button>
              </li>
            </ul>
          </div>
          <div className={styles.nav__content__menubar}>
            <MenuBar toggle={toggle} onOpenMenu={onOpenMenu} />
            {toggle && (
              <div className={styles.nav__content__menubar__mobmenu}>
                <ul>
                  <li>
                    <Link to="/" className={pathname === "/" ? styles.active : ""}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" className={pathname === "/about-us" ? styles.active : ""}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className={pathname === "/pricing" ? styles.active : ""}>
                      Pricing
                    </Link>
                  </li>
                </ul>
                <div className={styles.nav__content__menubar__mobmenu__signin}>
                  <ul>
                    <li>
                      <Link to="/signin">Signin</Link>
                    </li>
                    <li>
                      <button>
                        <Link to="/try-it-free">Try It Free</Link>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default DesktopNav;
