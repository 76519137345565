import React, { useState } from "react";
import styles from "./index.module.scss";
import Container from "../../shared/Container";
import contact1 from "../../assets/newicons/contact1.svg";
import contact2 from "../../assets/newicons/contact2.svg";
import contact3 from "../../assets/newicons/contact3.svg";
import InputFieldTitle from "../../shared/InputFieldTitle";
import Button from "../../shared/Button";
import TextAreaWithTitle from "../../shared/TextAreaWithTitle";
import SelectToTitle from "../../shared/SelectToTitle";
import toast from "react-hot-toast";
import { onContactUs } from "../../services/server";
import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const compnaySizes = [
  { id: 1, name: "<5" },
  { id: 2, name: "5-10" },
  { id: 3, name: "10-25" },
  { id: 4, name: "25-50" },
  { id: 5, name: "50-75" },
  { id: 6, name: "75-100" },
  { id: 7, name: " >100" },
];

export default function Contact() {
  const { ref, control } = useScroll();
  const formData = new FormData();
  const [loading, setLoading] = useState(false);
  const [enquiryDetails, setEnquiryDetails] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    company: "",
    compnaySizes: "",
  });

  const onContactField = (e, id) => {
    setEnquiryDetails((prev) => ({ ...prev, [id]: e.target.value }));
  };

  const onSubmitEnquiryForm = async () => {
    formData.append("Company", enquiryDetails.company);
    formData.append("Company_Size", enquiryDetails.compnaySizes);
    formData.append("User_Email", enquiryDetails.email);
    formData.append("Message", enquiryDetails.message);
    formData.append("User_Name", enquiryDetails.name);
    formData.append("User_MobileNo", enquiryDetails.phone);
    formData.append("Subject", enquiryDetails.subject);

    if (enquiryDetails.subject === "") {
      toast.error("Subject Is Required!", { duration: 1500 });
    } else if (enquiryDetails.compnaySizes === "") {
      toast.error("Select Your Company Size!", { duration: 1500 });
    } else if (enquiryDetails.name === "") {
      toast.error("Invalid Name!", { duration: 1500 });
    } else if (!enquiryDetails.email.includes("@") || !enquiryDetails.email.includes(".")) {
      toast.error("Inavlid Email!", { duration: 1500 });
    } else if (enquiryDetails.company === "") {
      toast.error("Invalid Company Name!", { duration: 1500 });
    } else if (enquiryDetails.phone.length !== 10) {
      toast.error("Invalid Phone Number!", { duration: 1500 });
    } else if (enquiryDetails.message === "") {
      toast.error("Questions Is Required!", { duration: 1500 });
    } else {
      setLoading(true);
      const response = await onContactUs(formData);

      if (response.data.status) {
        setLoading(false);
        setEnquiryDetails({
          name: "",
          phone: "",
          email: "",
          subject: "",
          message: "",
          company: "",
          compnaySizes: "",
        });
        toast.success(response.data.message, { duration: 1500 });
      } else {
        setLoading(false);
        toast.error(response.data.message, { duration: 1500 });
      }
    }
  };

  const topVar = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.9 } },
    hidden: { opacity: 0, scale: 0.8 },
  };

  return (
    <Container bg="#fff">
      <div className={styles.contact}>
        <div className={styles.contact__header}>
          <h3 className={styles.contact__header__title}>Feel free to share your information and we will contact you to fully understand your needs.</h3>
          <h3 className={styles.contact__header__title}>Don't like filling forms?</h3>
          <h3 className={styles.contact__header__title}>
            Email us
            <a href="mailto:contact@xstore.in" className="btn btn-text hovered">
              contact@xstore.in
            </a>
          </h3>
        </div>

        <div className={styles.contact__info}>
          <div className={styles.contact__info__left}>
            <h3>Stay in touch</h3>
            <InputFieldTitle id="subject" value={enquiryDetails.subject} onChange={onContactField} label="Subject" />
            <SelectToTitle onChange={onContactField} value={enquiryDetails.compnaySizes} label="Company Size" id="compnaySizes" items={compnaySizes} />
            <div className={styles.contact__info__left__oneline}>
              <InputFieldTitle id="name" value={enquiryDetails.name} onChange={onContactField} label="Your Name" />
              <InputFieldTitle type="email" id="email" value={enquiryDetails.email} onChange={onContactField} label="Your Email" />
            </div>
            <div className={styles.contact__info__left__oneline}>
              <InputFieldTitle id="company" value={enquiryDetails.company} onChange={onContactField} label="Company" />
              <InputFieldTitle type="number" id="phone" value={enquiryDetails.phone} onChange={onContactField} label="Mobile Number" />
            </div>

            <TextAreaWithTitle id="message" value={enquiryDetails.message} label="Questions" onChange={onContactField} />
            <p>Provide Your Questions After That We Will Help You Better.</p>
            <div className={styles.contact__info__left__btn}>
              <Button loading={loading} name="Submit" onClick={onSubmitEnquiryForm} />
            </div>
          </div>
          <motion.div ref={ref} variants={topVar} initial="visible" animate={control} className={styles.contact__info__right}>
            <div className={styles.contact__info__right__top}>
              <div className={styles.contact__info__right__top__box}>
                <div className={styles.contact__info__right__top__box__icon}>
                  <img src={contact1} alt="contact1" />
                </div>
                <div className={styles.contact__info__right__top__box__content}>
                  <h1>For inquiries and suggestions</h1>
                  <a href="mailto:contact@xstore.in" className="btn btn-text btn-text--black">
                    contact@xstore.in
                  </a>
                </div>
              </div>

              <div className={styles.contact__info__right__top__box}>
                <div className={styles.contact__info__right__top__box__icon}>
                  <img src={contact2} alt="contact2" />
                </div>
                <div className={styles.contact__info__right__top__box__content}>
                  <h1>Technical support</h1>
                  <a href="mailto:contact@xstore.in" className="btn btn-text btn-text--black">
                    contact@xstore.in
                  </a>
                </div>
              </div>
              <div className={styles.contact__info__right__top__box}>
                <div className={styles.contact__info__right__top__box__icon}>
                  <img src={contact3} alt="contact3" />
                </div>
                <div className={styles.contact__info__right__top__box__content}>
                  <h1>WhatsApp business</h1>
                  <a href="https://api.whatsapp.com/send/?phone=917080581133&text&app_absent=0" className="btn btn-text btn-text--black">
                    +91 7080581133
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.contact__info__right__bottom}>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d629.0275415402073!2d80.97235963006686!3d26.89342898410601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39995754fadda861%3A0xbddcc736f4743c8f!2sSkyview%20Smart%20Solutions-%20Best%20Website%20Designing%20%7C%20Software%20Development%20%7C%20Digital%20Marketing%20%7C%20SEO%20%7C%20IT%20Company%20in%20Lucknow!5e0!3m2!1sen!2sin!4v1702738335225!5m2!1sen!2sin"
                style={{ border: 0, width: "100%", height: "300px" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </motion.div>
        </div>
      </div>
    </Container>
  );
}
