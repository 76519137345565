import React from "react";
import "./Invoicing.css";
import InputFieldTitle from "../../../shared/InputFieldTitle";
import SelectToTitle from "../../../shared/SelectToTitle";

const Invoicing = (props) => {
  const {
    bank,
    setBank,
    accNumber,
    setAccNumber,
    accReceviable,
    setAccReceviable,
    accPayable,
    setAccPayable,
  } = props;

  const accountPayObj = [
    { id: 1, name: "210101001 Suppliers" },
    { id: 2, name: "210101001 Net SubContractors Proceeds" },
    { id: 3, name: "210101001 Employee Salaries Owed" },
  ];

  const accountRecObj = [
    { id: 1, name: "110301001 Ministry Of Transport" },
    { id: 2, name: "110301002 Royal commission Yanbu" },
    { id: 3, name: "Red Sea Company" },
  ];

  return (
    <div>
      <div className="invoicing">
        <div className="BankAccount">
          <h3>Bank Account</h3>
          <div className="responsiveDesign">
            <InputFieldTitle
              label="Bank"
              type="text"
              value={bank}
              onChange={(e) => setBank(e.target.value)}
            />
            <InputFieldTitle
              label="ccount Number"
              type="number"
              min="1"
              value={accNumber}
              onChange={(e) => setAccNumber(e.target.value)}
            />
          </div>
          {/* <div className="banktext">
            <p>Bank</p>
            <input
              type="text"
              value={bank}
              onChange={(e) => setBank(e.target.value)}
            />
          </div> */}
          {/* <div className="banktext">
            <p>Account Number</p>
            <input
              type="number"
              min="1"
              value={accNumber}
              onChange={(e) => setAccNumber(e.target.value)}
            />
          </div> */}
        </div>
        <div className="Accounting">
          <h3>Accounting Entries</h3>
          <div className="responsiveDesign">
            <SelectToTitle
              label="Account Receivable"
              items={accountRecObj}
              value={accReceviable}
              onChange={(e) => setAccReceviable(e.target.value)}
            />
            <SelectToTitle
              label="Account Payable"
              items={accountPayObj}
              value={accPayable}
              onChange={(e) => setAccPayable(e.target.value)}
            />
          </div>
          {/* <div className="banktext">
            <p>Account Receivable</p>
            <select
              value={accReceviable}
              onChange={(e) => setAccReceviable(e.target.value)}
            >
              <option value="">Select any one</option>
              <option value="110301001  Ministry Of Transport">
                110301001 Ministry Of Transport
              </option>
              <option value="110301002 Royal commission Yanbu">
                110301002 Royal commission Yanbu
              </option>
              <option value="110301003 Ministry Of Muncipal and Ruler Affairs">
                110301003 Ministry Of Muncipal and Ruler Affairs
              </option>
              <option value="Red Sea Company">Red Sea Company</option>
            </select>
          </div> */}
          {/* <div className="banktext">
            <p>Account Payable</p>
            <select
              value={accPayable}
              onChange={(e) => setAccPayable(e.target.value)}
            >
              <option value="">Choose any one</option>
              <option value="210101001 Suppliers">210101001 Suppliers</option>
              <option value="210101001 Net SubContractors Proceeds">
                210101001 Net SubContractors Proceeds
              </option>
              <option value="210101001 Employee Salaries Owed">
                210101001 Employee Salaries Owed
              </option>
            </select>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Invoicing;
