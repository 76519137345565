import React from "react";

import Xstore from "./Xstore";
import styles from "./index.module.scss";
// import footer from "../../assets/newicons/footer-shape.svg";
import Container from "../../shared/Container";
import wave from "../../assets/wave.png";
import Company from "./Company";
import ContactUs from "./ContactUs";
import Working from "./Working";
import Newsletter from "./Newsletter";
import SubFooter from "./SubFooter";

const Footer = () => {
  return (
    <div className={styles.footer}>
      {/* <img src={footer} alt="footer shape" className={styles.footer__shape} fill /> */}
      <div className={styles.footer__shape2}>
        <img src={wave} alt="footer shape" style={{ objectFit: "contain" }} />
      </div>
      <Newsletter />
      <Container>
        <div className={styles.footer__mid}>
          <Xstore width="30%" />
          <Company width="16%" />
          <Working width="25%" />
          <ContactUs width="24%" />
        </div>
      </Container>
      <hr className={styles.footer__hr2} />
      <SubFooter />
    </div>
  );
};

export default Footer;
