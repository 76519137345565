import React from "react";
import styles from "./index.module.scss";
import FooterTitle from "../../FooterTitle";
import Lists from "../Lists";
import { motion } from "framer-motion";
import useScroll from "../../../hooks/useScroll";

const Working = ({ width }) => {
  const { ref, control } = useScroll();
  const items = [
    { id: 1, name: "Mon - Fri: 9:00 AM - 5:00 PM" },
    { id: 2, name: "Saturday: 10:00 AM - 6:00 PM" },
    { id: 3, name: "Sunday Closed" },
  ];
  const variant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, y: 100 },
  };
  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} style={{ width: width }} className={styles.working}>
      <FooterTitle title="Working Timing" />
      <Lists items={items} />
    </motion.div>
  );
};

export default Working;
