import React from "react";
import styles from "./index.module.scss";

const MovingButton = () => {
  return (
    <button class={styles.btn23}>
      <span class={styles.text}>LOGOUT</span>
      <span aria-hidden="true" class={styles.marquee}>
        LOGOUT
      </span>
    </button>
  );
};

export default MovingButton;
