import React from "react";
import styles from "./index.module.scss";
import { FaPhone, FaLocationDot, FaEnvelope, FaInstagram, FaLinkedinIn, FaFacebookF } from "react-icons/fa6";
import LeftItem from "./LeftItem";
import RightItem from "./RightItem";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.header__content}>
        <ul className={styles.header__content__left}>
          <LeftItem icon={<FaPhone />} val="+91 9876543210" />
          <LeftItem icon={<FaLocationDot />} val="19 King Street. Berlin MU 30, Germany" />
          <LeftItem icon={<FaEnvelope />} val="info@example.com" />
        </ul>
        <ul className={styles.header__content__right}>
          <RightItem val="Follow Us:" />
          <RightItem val={<FaInstagram />} />
          <RightItem val={<FaFacebookF />} />
          <RightItem val={<FaLinkedinIn />} />
        </ul>
      </div>
    </header>
  );
};

export default Header;
