import React from "react";
import styles from "./index.module.scss";
import FooterTitle from "../../FooterTitle";
import Lists from "../Lists";
import useScroll from "../../../hooks/useScroll";
import { motion } from "framer-motion";

const Company = ({ width }) => {
  const items = [
    { id: 1, name: "Help Center" },
    { id: 2, name: "Terms & Conditions" },
    { id: 3, name: "Privacy Policy" },
    { id: 4, name: "Contact Us" },
  ];
  const { ref, control } = useScroll();
  const variant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    hidden: { opacity: 0, y: 100 },
  };
  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} style={{ width: width }} className={styles.company}>
      <FooterTitle title="Company" />
      <Lists items={items} />
    </motion.div>
  );
};

export default Company;
