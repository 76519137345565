import Container from "../../shared/Container";
import SectionTitle from "../../shared/SectionTitle";
import React from "react";
import styles from "./index.module.scss";

import frontdesk from "../../assets/icons/Frontdesk.png";
import expenses from "../../assets/icons/expenseDevice.png";
import inventory from "../../assets/icons/inventory.png";
import iot from "../../assets/icons/iot.png";
import kiosk from "../../assets/icons/kiosk.png";
import pos from "../../assets/icons/pos.png";
import shopfloor from "../../assets/icons/shopFloor.png";
import SingleDevice from "../SingleDevice";
import { motion } from "framer-motion";

const AllDevices = () => {
  const devices = [
    { id: 1, title: "Frontdesk", src: frontdesk },
    { id: 2, title: "Expenses", src: expenses },
    { id: 3, title: "Inventory", src: inventory },
    { id: 4, title: "Kiosk", src: kiosk },
    { id: 5, title: "IoT", src: iot },
    { id: 6, title: "Shop Floor", src: shopfloor },
    { id: 7, title: "Point of Sale", src: pos },
  ];
  return (
    <div className={styles.allDevices}>
      <Container>
        <SectionTitle title="Optimized productivity" desc="Unleash your growth potential" />

        <motion.div initial="hidden" animate="visible" className={styles.allDevices__content}>
          {devices.map((item) => {
            return <SingleDevice key={item.id} title={item.title} src={item.src} />;
          })}
        </motion.div>
      </Container>
    </div>
  );
};

export default AllDevices;
