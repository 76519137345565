import React from "react";
import styles from "./index.module.scss";

const InputFieldTitle = ({ type, id, label, onChange, value, readOnly, maxLength, min }) => {
  return (
    <div className={`${styles.formGroup} ${styles.field}`}>
      <input
        onChange={(e) => onChange(e, id)}
        type={type}
        value={value}
        className={styles.formGroup__formField}
        disabled={readOnly}
        placeholder={label}
        name={label}
        id={id}
        maxLength={maxLength}
        required
        min={min}
      />
      <label htmlFor={id} className={styles.formGroup__formLabel}>
        {label}
      </label>
    </div>
  );
};

export default InputFieldTitle;
