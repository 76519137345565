import React from "react";
import styles from "./index.module.scss";

const TextAreaWithTitle = ({ id, label, onChange, value }) => {
  return (
    <div className={`${styles.formGroup} ${styles.field}`}>
      <textarea value={value} onChange={(e) => onChange(e, id)} className={styles.formGroup__formField} placeholder={label} name={label} id={id} required />
      <label htmlFor={id} className={styles.formGroup__formLabel}>
        {label}
      </label>
    </div>
  );
};

export default TextAreaWithTitle;
