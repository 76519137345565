import React from "react";
import styles from "./index.module.scss";
import { FaCheckCircle } from "react-icons/fa";
import { IoArrowForward } from "react-icons/io5";
import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const PricingCard = ({ price, items, onGetPlan, variant }) => {
  const { ref, control } = useScroll();
  const apps = items.Description.split(",");

  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} className={styles.pricingCard}>
      <div className={styles.pricingCard__box}>
        <p>/User/Month</p>
        <h2>$ {price}</h2>
      </div>
      <div className={styles.pricingCard__package}>
        <p>Starter Package</p>
        <h4>{items.PackageName} Plan</h4>
      </div>
      <ul>
        {apps.map((item, ind) => {
          return (
            <li key={ind}>
              <FaCheckCircle /> {item}
            </li>
          );
        })}
      </ul>
      <button onClick={() => onGetPlan(items, price)} className={styles.pricingCard__btn}>
        Get Started <IoArrowForward className={styles.pricingCard__btn__icon} />
      </button>
    </motion.div>
  );
};

export default PricingCard;
