import React from "react";

import contact from "../../assets/newicons/contact.gif";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";

const FloatingAction = () => {
  const navigate = useNavigate();
  const onNavigateContact = () => {
    navigate("/contact-us");
  };
  return (
    <div onClick={onNavigateContact} className={styles.floatingAction}>
      <img src={contact} alt="contact" />
    </div>
  );
};

export default FloatingAction;
