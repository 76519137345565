"use client";
import Container from "../../shared/Container";
import React from "react";
import TestimonialCard from "../TestimonialCard";
import bottomWave from "../../assets/bottomWave.png";
import styles from "./index.module.scss";
import SectionTitle from "../../shared/SectionTitle";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonials = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
    },
    LargeDesktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 992, min: 768 },
      items: 1,
      partialVisibilityGutter: 200,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={styles.cont}>
      <div className={styles.wave}>
        <img src={bottomWave} alt="bottomwave" className={styles.wave__img} />
      </div>
      <Container>
        <div className={styles.top}>
          <SectionTitle title="our experience" desc="Trusted By Global Client’s" />
        </div>
        <div className={styles.content}>
          <Carousel
            swipeable={true}
            partialVisible={true}
            draggable={true}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            responsive={responsive}>
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;
