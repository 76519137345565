import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import styles from "./index.module.scss";

const CloseIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} className={styles.closeIcon}>
      <IoCloseSharp className={styles.closeIcon__icon} />
    </div>
  );
};

export default CloseIcon;
