import React from "react";

import retail from "../../assets/retail.webp";
import Banner from "../../components/Banner";
import ChooseApp from "../../components/ChooseApp";

const Trial = () => {
  return (
    <>
      <Banner banner={retail} title="Choose your Apps" desc="Free instant access, no credit card required." />
      <ChooseApp />
    </>
  );
};

export default Trial;
