import React from "react";
import styles from "./index.module.scss";

const Lists = ({ items }) => {
  return (
    <ul className={styles.ul}>
      {items.map((item) => {
        return (
          <li key={item.id} className={styles.ul__lists}>
            {item.name}
          </li>
        );
      })}
    </ul>
  );
};

export default Lists;
