import React, { useEffect } from "react";
import DesktopNav from "../../components/DesktopNav";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import FloatingAction from "../../components/FloatingAction";

const Public = ({ element }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <DesktopNav />
      {element}
      {pathname !== "/contact-us" && <FloatingAction />}
      <Footer />
    </>
  );
};

export default Public;
