import React from "react";
import styles from "./index.module.scss";

import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const SingleApp = ({ src, title, onSelectPlatform, selectedAppsId, id, variant }) => {
  const { ref, control } = useScroll();
  return (
    <motion.label
      ref={ref}
      variants={variant}
      initial="hidden"
      animate={control}
      style={{ backgroundColor: selectedAppsId.length === 2 ? (selectedAppsId.includes(id) ? "white" : "#c5c7c8") : "" }}
      className={styles.label}>
      <div className={styles.serviceItem}>
        <div style={{ backgroundColor: selectedAppsId.length === 2 ? (selectedAppsId.includes(id) ? "white" : "#c5c7c8") : "" }} className={styles.serviceItem__item}>
          <img src={src} alt={title} />
        </div>
        <h4 className={styles.serviceItem__title}>{title}</h4>
      </div>
      <div className={styles.label__div}>
        <input
          disabled={selectedAppsId.length === 2 ? (selectedAppsId.includes(id) ? false : true) : ""}
          className={styles.label__div__input}
          onChange={onSelectPlatform}
          id="checkbox"
          type="checkbox"
        />
        <div className={styles.label__div__div}></div>
      </div>
    </motion.label>
  );
};

export default SingleApp;
