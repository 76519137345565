import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import PricingCard from "../PricingCard";
import Container from "../../shared/Container";
import SectionTitle from "../../shared/SectionTitle";
import { onGetPrice } from "../../services/server";
import { useDispatch, useSelector } from "react-redux";
import { uiSliceAction } from "../../redux/reducers/ui";
import Register from "../../modals/Register";

const Pricing = () => {
  const dispatch = useDispatch();
  const isRegister = useSelector((state) => state.ui.isResgister);
  const [isMonth, setIsMonth] = useState(false);
  const [pricingPack, setPricingPack] = useState([]);
  const [selectedPack, setSelectedPack] = useState([]);

  const onSelected = (e) => {
    setIsMonth(e.target.checked);
  };

  const onPricePackage = async () => {
    const response = await onGetPrice();

    if (response.data.status) {
      setPricingPack(response.data.Data);
    } else {
      setPricingPack(response.data.Data);
    }
  };

  useEffect(() => {
    onPricePackage();
  }, []);

  const onGetPlan = (it, price) => {
    const packageSelect = {
      packageId: it.SRno,
      price: price,
    };
    setSelectedPack(packageSelect);
    dispatch(uiSliceAction.onOpenRegistration());
  };

  const topVar = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.9 } },
    hidden: { opacity: 0, y: 200 },
  };

  const botVar = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, y: -200 },
  };

  return (
    <>
      {isRegister && <Register selectedPack={selectedPack} />}
      <div className={styles.pricing}>
        <div className={styles.cont}>
          <h3>Monthly</h3>
          <div className={styles.pricing__switch}>
            <input checked={isMonth} onChange={onSelected} className={styles.pricing__switch__checkbox} id="checkbox1" name="checkbox" type="checkbox" />
            <label className={styles.pricing__switch__label} htmlFor="checkbox1"></label>
          </div>
          <h3>Yearly</h3>
        </div>
        <Container>
          <SectionTitle title="Pick a plan that fits your needs" desc="You are not dreaming!" />
          <div className={styles.pricing__content}>
            {pricingPack.map((item, ind) => {
              return (
                <PricingCard variant={(ind + 1) % 2 === 0 ? botVar : topVar} onGetPlan={onGetPlan} key={ind} items={item} price={isMonth ? item.YearlyPrice : item.MonthlyPrice} />
              );
            })}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Pricing;
