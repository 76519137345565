import React from "react";
import ServiceItem from "../ServiceItem";
import inventory from "../../assets/newicons/inventory.svg";
import purchase from "../../assets/newicons/purchase.svg";
import accounting from "../../assets/newicons/accounting.svg";
import employees from "../../assets/newicons/employee.svg";
import sales from "../../assets/newicons/sales.svg";
import expenses from "../../assets/newicons/expenses.svg";
import styles from "./index.module.scss";
import Button from "../../shared/Button";
import Container from "../../shared/Container";

const AboutFuture = () => {
  return (
    <Container>
      <section className={styles.aboutFuture}>
        <div className={styles.aboutFuture__left}>
          <h4>Future is brighter when you’re more prepared</h4>
          <p>We help you weather today's uncertainty through our best team needs.</p>
          <Button name="View More" />
        </div>
        <div className={styles.aboutFuture__right}>
          <ServiceItem src={inventory} title="Inventory" />
          <ServiceItem src={purchase} title="purchase" />
          <ServiceItem src={accounting} title="accounting" />
          <ServiceItem src={sales} title="sales" />
          <ServiceItem src={expenses} title="expenses" />
          <ServiceItem src={employees} title="employees" />
        </div>
      </section>
    </Container>
  );
};

export default AboutFuture;
