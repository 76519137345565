import { useRef, useState } from "react";

import classes from "./index.module.scss";

export default function ImagePicker({ label, name, setLogo }) {
  const [pickedImage, setPickedImage] = useState();
  const imageInput = useRef();

  function handlePickClick() {
    imageInput.current.click();
  }

  function handleImageChange(event) {
    const file = event.target.files[0];

    if (!file) {
      setPickedImage(null);
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setPickedImage(fileReader.result);
    };
    setLogo(file);
    fileReader.readAsDataURL(file);
  }

  return (
    <div className={classes.picker}>
      <label htmlFor={name}>{label}</label>
      <div className={classes.controls}>
        <div onClick={handlePickClick} className={classes.preview}>
          {!pickedImage && <p>Pick Company Logo.</p>}
          {pickedImage && <img src={pickedImage} alt="The logo selected by the user." />}
        </div>
        <input className={classes.input} type="file" id={name} accept="image/png, image/jpeg" name={name} ref={imageInput} onChange={handleImageChange} />
        {/* <button className={classes.button} type="button" onClick={handlePickClick}>
          Pick An Logo
        </button> */}
        <p>Company Logo</p>
      </div>
    </div>
  );
}
