import React from "react";
import styles from "./index.module.scss";
import wave from "../../assets/wave.png";
import left from "../../assets/floating_image_01.png";
import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const Banner = (props) => {
  const { ref, control } = useScroll();

  const h1Var = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.9 } },
    hidden: { opacity: 0, y: 20 },
  };

  const pVar = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, y: -20 },
  };
  const imgVar = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
    hidden: { opacity: 0, scale: 0.5 },
  };

  return (
    <div style={{ backgroundImage: `url(${props.banner})` }} className={styles.banner}>
      {/* <h1>All-In-One Platform For All Your Businesses</h1> */}
      <div className={styles.banner__content}>
        <motion.h1 ref={ref} variants={h1Var} initial="hidden" animate={control}>
          {props.title}
        </motion.h1>
        <motion.p ref={ref} variants={pVar} initial="hidden" animate={control}>
          {props.desc}
        </motion.p>
      </div>
      <div className={styles.banner__overlay}>
        <img src={wave} alt="wave" className={styles.banner__overlay__img} style={{ objectFit: "contain" }} />
      </div>
      <motion.img ref={ref} variants={imgVar} initial="hidden" animate={control} src={left} alt="wave" className={styles.banner__left} style={{ objectFit: "contain" }} />
    </div>
  );
};

export default Banner;
