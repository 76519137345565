import React from "react";

import user from "../../assets/newicons/user.svg";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const TestimonialCard = () => {
  const { ref, control } = useScroll();
  const variant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
    hidden: { opacity: 0, scale: 0.5 },
  };
  return (
    <motion.div variants={variant} ref={ref} initial="hidden" animate={control} className={styles.testimonialCard}>
      <div className={styles.testimonialCard__user}>
        <img className={styles.testimonialCard__user__img} src={user} alt="clients" />
      </div>
      <div className={styles.testimonialCard__icon1}>
        <RiDoubleQuotesL className={styles.testimonialCard__icon1__quotes} />
      </div>
      <div className={styles.testimonialCard__icon2}>
        <RiDoubleQuotesR className={styles.testimonialCard__icon2__quotes} />
      </div>
      <p className={styles.testimonialCard__message}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vel explicabo deserunt assumenda atque doloremque alias libero, ut, enim ducimus vitae! Similique pariatur
        rerum excepturi nostrum reiciendis libero doloremque voluptates, veniam commodi magnam non temporibus omnis laudantium nemo eaque aut vel ea ad est iure delectus inventore?
        Repellat, velit doloribus?
      </p>
      <div className={styles.testimonialCard__bottom}>
        <h4>Mohd Ale Mustafa</h4>
        <p>CEO, Microsoft</p>
      </div>
    </motion.div>
  );
};

export default TestimonialCard;
