"use client";
import SectionTitle from "../../shared/SectionTitle";
import styles from "./index.module.scss";
import Tabs from "./Tab";
import { useState } from "react";

const OurMission = () => {
  const [tabClicked, setTabClicked] = useState("Our Mission");
  const tabs = [
    { id: 1, name: "Our Mission" },
    { id: 2, name: "Our Vision" },
    { id: 3, name: "Our Goal" },
  ];

  const onTabClicked = (n) => {
    setTabClicked(n);
  };

  return (
    <div className={styles.ourMission}>
      <SectionTitle title="About Mission" desc="Our Main Goal to Satisfied local & Global Clients" />
      <Tabs onTabClicked={onTabClicked} tabs={tabs} tabClicked={tabClicked} />
      {tabClicked === "Our Mission" && (
        <div className={styles.ourMission__content}>
          <p>
            The history of logistics dates back ancient civilizations, where the development of transportation systems & trade networks played crucial role the exchange of goods
            and information. With the rise of large-scale commerce. Sapien montes euismod penatibus consequat vivamus hendrerit nisl non eleifend nascetur leo auctor fames velit
            solutions.
          </p>
        </div>
      )}
      {tabClicked === "Our Vision" && (
        <div className={styles.ourMission__content}>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo quibusdam dolores dicta facere dolore impedit est perspiciatis saepe quas dolor doloremque eligendi,
            ab ipsa laborum quisquam pariatur nulla adipisci ad inventore atque quis harum, expedita error. Maxime accusamus fuga aut expedita quis! Consectetur deleniti nemo,
            facilis neque quisquam provident delectus!
          </p>
        </div>
      )}
      {tabClicked === "Our Goal" && (
        <div className={styles.ourMission__content}>
          <p>
            The history of logistics dates back ancient civilizations, where the development of transportation systems & trade networks played crucial role the exchange of goods
            and information. With the rise of large-scale commerce. Sapien montes euismod penatibus consequat vivamus hendrerit nisl non eleifend nascetur leo auctor fames velit
            solutions.
          </p>
        </div>
      )}
    </div>
  );
};

export default OurMission;
