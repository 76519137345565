import React from "react";
import styles from "./index.module.scss";
import { MdArrowForwardIos } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";

const FaqAccordion = ({ question, answer, id, openAccordion, show }) => {
  return (
    <>
      <motion.div onClick={openAccordion} animate={{ backgroundColor: show === id ? "#fabe10" : "" }} style={{ borderTop: id === 1 ? "none" : "" }} className={styles.faqAccordion}>
        <h3 style={{ color: show === id ? "#fff" : "" }}>{question}</h3>
        <MdArrowForwardIos style={{ transform: show === id ? "rotate(90deg)" : "", color: show === id ? "#fff" : "" }} className={styles.faqAccordion__icon} />
      </motion.div>
      <AnimatePresence initial={false}>
        {show === id && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto", width: "100%" },
              collapsed: { opacity: 0, height: 0, width: "100%" },
            }}
            transition={{ duration: 0.3 }}
            className={styles.faqAccordion2}>
            <motion.p variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }} transition={{ duration: 0.3 }}>
              {answer}
            </motion.p>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FaqAccordion;
