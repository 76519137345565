import React from "react";
import styles from "./index.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneField = ({
  type,
  id,
  label,
  onChange,
  value,
  readOnly,
  maxLength,
  min,
}) => {
  return (
    <div className={`${styles.formGroup} ${styles.field}`}>
      <PhoneInput
        country={"in"}
        value={value}
        onChange={(e) => onChange(e, id)}
        containerClass={styles.formGroup__formField}
      />
      {/* <input
        onChange={(e) => onChange(e, id)}
        type={type}
        value={value}
        className={styles.formGroup__formField}
        disabled={readOnly}
        placeholder={label}
        name={label}
        id={id}
        maxLength={maxLength}
        required
        min={min}
      /> */}
      <label htmlFor={id} className={styles.formGroup__formLabel}>
        {label}
      </label>
    </div>
  );
};

export default PhoneField;
