import Banner from "../../components/Banner";
import React from "react";
import bg from "../../assets/pricing.jpg";
import Pricing from "../../components/Pricing";
import Startup from "../../components/Startup";

const PricingPage = () => {
  return (
    <>
      <Banner banner={bg} title="Price Packs" desc="Simple, Efficient & Affordable!" />
      <Pricing />
      <Startup />
    </>
  );
};

export default PricingPage;
