import FooterTitle from "../../FooterTitle";
import React from "react";
import { FaFacebookF, FaXTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa6";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useScroll from "../../../hooks/useScroll";

const Xstore = ({ width }) => {
  const { ref, control } = useScroll();
  const variant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: 100 },
  };
  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} style={{ width: width }} className={styles.xstore}>
      <FooterTitle title="Xstore Suite" />
      <p className={styles.xstore__desc}>
        Xstore Suite simplifies process and empowers more people.
        <br /> Imagine the impact when everyone gets the right tool for the job, with perfect integration.
      </p>
      <ul className={styles.xstore__socialLinks}>
        <li className={styles.xstore__socialLinks__icons}>
          <FaFacebookF className={styles.xstore__socialLinks__icons__icon} />
        </li>
        <li className={styles.xstore__socialLinks__icons}>
          <FaXTwitter className={styles.xstore__socialLinks__icons__icon} />
        </li>
        <li className={styles.xstore__socialLinks__icons}>
          <FaLinkedinIn className={styles.xstore__socialLinks__icons__icon} />
        </li>
        <li className={styles.xstore__socialLinks__icons}>
          <FaInstagram className={styles.xstore__socialLinks__icons__icon} />
        </li>
      </ul>
    </motion.div>
  );
};

export default Xstore;
