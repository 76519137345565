import React from "react";
import styles from "./index.module.scss";

const RightItem = (props) => {
  return (
    <li className={styles.rightItem}>
      <span>{props.val}</span>
    </li>
  );
};

export default RightItem;
