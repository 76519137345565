import { useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const useScroll = () => {
  const control = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, isInView]);

  return {
    control,
    ref,
  };
};

export default useScroll;
