import React from "react";

import Banner from "../../components/Banner";
import AllServices from "../../components/AllServices";
import AllDevices from "../../components/AllDevices";
import bg from "../../assets/inventory2.jpg";
import Testimonials from "../../components/Testimonials";

const Home = () => {
  return (
    <>
      <Banner banner={bg} title="All Solutions For Your Businesses" desc="Simple, Efficient & Affordable!" />
      <AllServices />
      <AllDevices />
      <Testimonials />
    </>
  );
};

export default Home;
