import React from "react";
import styles from "./index.module.scss";

const LeftItem = (props) => {
  return (
    <li className={styles.leftItem}>
      {props.icon}
      <span>{props.val}</span>
    </li>
  );
};

export default LeftItem;
