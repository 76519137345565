import ReactDom from "react-dom";
import styles from "./index.module.scss";
import { Fragment } from "react";

const Overlay = ({ children }) => {
  return <div className={styles.overlay}>{children}</div>;
};

const Modal = ({ children }) => {
  return <Fragment>{ReactDom.createPortal(<Overlay>{children}</Overlay>, document.getElementById("modal"))}</Fragment>;
};

export default Modal;
