import React from "react";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useScroll from "../../../hooks/useScroll";

const SubFooter = () => {
  const date = new Date();
  const year = date.getFullYear();
  const { ref, control } = useScroll();
  const variant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: 20 },
  };
  const variant2 = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: 20 },
  };
  return (
    <motion.div ref={ref} variants={variant2} initial="hidden" animate={control} className={styles.subFooter}>
      <motion.p ref={ref} variants={variant} initial="hidden" animate={control}>
        © {year} Xstore Suite. All Rights Reserved.
      </motion.p>
    </motion.div>
  );
};

export default SubFooter;
