import React from "react";
import styles from "./index.module.scss";

const ParentLocSelect = ({ id, label, items, onChange, value }) => {
  return (
    <div className={`${styles.formGroup} ${styles.field}`}>
      <select onChange={(e) => onChange(e, id)} value={value} className={styles.formGroup__formField} placeholder={label} name={label} id={id} required>
        <option value="">Choose One Option</option>
        {items.map((itm, ind) => {
          return (
            <option value={itm.LOCATION_NAME} key={ind}>
              {itm.LOCATION_NAME}
            </option>
          );
        })}
      </select>
      <label htmlFor={id} className={styles.formGroup__formLabel}>
        {label}
      </label>
    </div>
  );
};

export default ParentLocSelect;
