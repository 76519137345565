import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { endpoints } from "../../../services/endpoints";
import AccountNavbar from "../../AccountNavbar/AccountNavbar";
import Navebar from "../../Navbar/Navbar";
import VendorsCard from "../../VendorsCard/VendorsCard";
import "./Vendors.css";
import Loader from "../../../shared/Loader";

const Vendors = () => {
  const navigate = useNavigate();
  const [vendorsAll, setVendorsAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deActiveVendor, setDeActiveVendor] = useState([]);
  const [activeVendor, setActiveVendor] = useState([]);
  const getAuthtoken = localStorage.getItem("authtoken");
  const userAuth = localStorage.getItem("userAuth");
  const vendorsAllUrl = endpoints.vendors.allVendors;

  const getVendors = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("User_Authorization", getAuthtoken);
    formData.append("User_AuthKey", userAuth);
    axios
      .post(vendorsAllUrl, formData)
      .then((res) => {
        if (res.data.status === true) {
          const vendor = res.data.data;
          var val = vendor.reverse();

          const deletedVendor = val.filter((itm, ind) => {
            return itm.VENDOR_STATUS === "X";
          });
          setDeActiveVendor(deletedVendor);

          const allVendor = vendor.filter((itm, index) => {
            return itm.VENDOR_STATUS === null;
          });
          setActiveVendor(allVendor);

          setVendorsAll(res.data.data);
          setLoading(false);
        } else if (res.data.status === false) {
          toast(res.data.message, { type: "error" });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    getVendors();
  }, []);

  const handleCreatePage = () => {
    navigate("/AddVendors");
  };

  return (
    <div className="MainWarehouse">
      <div className="wareTop">
        <Navebar showBelowMenu={true} title="Vendors" handleCreatePage={handleCreatePage} />
      </div>
      <div className="wareBot">
        {loading ? (
          <div style={{ width: "100%", height: "100%" }} className="loader">
            <Loader bg="#fabe10" />
          </div>
        ) : (
          <div className="vendor_Container">
            {activeVendor.map((item, index) => {
              return <VendorsCard key={index} data={item} getVendors={getVendors} />;
            })}
            {deActiveVendor.map((item, index) => {
              return <VendorsCard key={index} data={item} getVendors={getVendors} />;
            })}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Vendors;
