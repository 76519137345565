import React from "react";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const SingleDevice = ({ src, title }) => {
  const { control, ref } = useScroll();
  const variant = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
    hidden: { opacity: 0, x: 50 },
  };

  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} className={styles.singleDevice}>
      <div className={styles.singleDevice__img}>
        <img className={styles.singleDevice__img__i} src={src} alt={title} />
      </div>
      {/* <img className={styles.singleDevice__img} src={mockup} alt={title} /> */}

      <h4>{title}</h4>
    </motion.div>
  );
};

export default SingleDevice;
