import React from "react";

import styles from "./index.module.scss";

const MenuBar = ({ onOpenMenu, toggle }) => {
  return (
    <div>
      <input onChange={onOpenMenu} className={styles.checkbox} type="checkbox" id="checkbox" checked={toggle} />
      <label htmlFor="checkbox" className={styles.toggle}>
        <div className={`${styles.bars} ${styles.bar1}`}></div>
        <div className={`${styles.bars} ${styles.bar2}`}></div>
        <div className={`${styles.bars} ${styles.bar3}`}></div>
      </label>
    </div>
  );
};

export default MenuBar;
