import React from "react";
import "./SalesPurchase.css";
import SelectToTitle from "../../../shared/SelectToTitle";
import InputFieldTitle from "../../../shared/InputFieldTitle";
const SalesPurchase = (props) => {
  const {
    salesPerson,
    setSalesPersons,
    salesPaymentTerms,
    setSalesPaymentTerms,
    priceList,
    setPriceList,
    purchasePaymentTerms,
    setPurchaseSalesPaymentTerms,
    supplierCurrency,
    setSupplierCurrency,
    fiscalPosition,
    setFiscalPosition,
    refrence,
    setRefrence,
    industry,
    setIndustry,
  } = props;

  const salesPersonObj = [
    { id: 1, name: "Abd Alla Mohamed Ahmed" },
    { id: 2, name: "Abdel Hamid Ali Altawaita" },
    { id: 3, name: "Abdulaziz Fangal Sultan Alotaibi" },
    { id: 4, name: "Abdul Basit Abdul Ghani" },
    { id: 5, name: "Abdulmajeed Zidan Khan" },
  ];

  const paymentTermsObj = [
    { id: 1, name: "Immediat Payment" },
    { id: 2, name: "15 Days" },
    { id: 3, name: "21 Days" },
    { id: 4, name: "30 Days" },
    { id: 5, name: "45 Days" },
    { id: 6, name: "2 Months" },
  ];

  const industryObj = [
    { id: 1, name: "Administrative" },
    { id: 2, name: "Agriculture" },
    { id: 3, name: "Construction" },
    { id: 4, name: "Education" },
    { id: 5, name: "Entertainment" },
    { id: 6, name: "Extraterritorial" },
  ];

  const priceListObj = [{ id: 1, name: "Public Pricelist (EUR)" }];

  const supCurrencyObj = [{ id: 1, name: "SAR" }];

  const fiscalPositionObj = [{ id: 1, name: "Immediat Payment" }];

  return (
    <div>
      <div className="SalesCon">
        <div className="SalesContent1">
          <div className="Sales">
            <h3>Sales</h3>
            <div className="responsiveDesign">
              <SelectToTitle
                items={salesPersonObj}
                label="Salesperson"
                value={salesPerson}
                onChange={(e) => setSalesPersons(e.target.value)}
              />
              <SelectToTitle
                items={paymentTermsObj}
                label="Payment Terms"
                value={salesPaymentTerms}
                onChange={(e) => setSalesPaymentTerms(e.target.value)}
              />
              <SelectToTitle
                items={priceListObj}
                label="Price List"
                value={priceList}
                onChange={(e) => setPriceList(e.target.value)}
              />
            </div>
            {/* <div className="inputText">
              <p>Salesperson</p>
              <select
                value={salesPerson}
                onChange={(e) => setSalesPersons(e.target.value)}
              >
                <option value="">Select Any One</option>
                <option value="Abd Alla Mohamed Ahmed">
                  Abd Alla Mohamed Ahmed
                </option>
                <option value="Abdel Hamid Ali Altawaita">
                  Abdel Hamid Ali Altawaita
                </option>
                <option value="Abdulaziz Fangal Sultan Alotaibi">
                  Abdulaziz Fangal Sultan Alotaibi
                </option>
                <option value="Abdul Basit Abdul Ghani">
                  Abdul Basit Abdul Ghani
                </option>
                <option value="Abdulmajeed Zidan Khan">
                  Abdulmajeed Zidan Khan
                </option>
              </select>
            </div> */}
            {/* <div className="inputText">
              <p>Payment Terms</p>
              <select
                value={salesPaymentTerms}
                onChange={(e) => setSalesPaymentTerms(e.target.value)}
              >
                <option value="">Choose any one</option>
                <option value="Immediat Payment">Immediat Payment</option>
                <option value="15 Days">15 Days</option>
                <option value="21 Days">21 Days</option>
                <option value="30 Days">30 Days</option>
                <option value="45 Days">45 Days</option>
                <option value="2 Months">2 Months</option>
              </select>
            </div> */}
            {/* <div className="inputText">
              <p>Price List</p>
              <select
                value={priceList}
                onChange={(e) => setPriceList(e.target.value)}
              >
                <option value="">Choose any one</option>
                <option value="Public Pricelist (EUR)">
                  Public Pricelist (EUR)
                </option>
              </select>
            </div> */}
          </div>
          <div className="Purchase">
            <h3>Purchase</h3>
            <div className="responsiveDesign">
              <SelectToTitle
                items={paymentTermsObj}
                label="Payment Terms"
                value={purchasePaymentTerms}
                onChange={(e) => setPurchaseSalesPaymentTerms(e.target.value)}
              />
              <SelectToTitle
                items={supCurrencyObj}
                label="Supplier Currency"
                value={supplierCurrency}
                onChange={(e) => setSupplierCurrency(e.target.value)}
              />
            </div>
            {/* <div className="inputText">
              <p>Payment Terms</p>
              <select
                value={purchasePaymentTerms}
                onChange={(e) => setPurchaseSalesPaymentTerms(e.target.value)}
              >
                <option value="">Choose any one</option>
                <option value="Immediat Payment">Immediate Payment</option>
                <option value="15 Days">15 Days</option>
                <option value="21 Days">21 Days</option>
                <option value="30 Days">30 Days</option>
                <option value="45 Days">45 Days</option>
                <option value="2 Months">2 Months</option>
              </select>
            </div> */}
            {/* <div className="inputText">
              <p>Supplier Currency</p>
              <select
                value={supplierCurrency}
                onChange={(e) => setSupplierCurrency(e.target.value)}
              >
                <option value="">Select Any One</option>
                <option value="SAR">SAR</option>
              </select>
            </div> */}
          </div>
        </div>
        <div className="SalesContent2">
          <div className="Fisical">
            <h3>Fiscal Information</h3>
            <div className="responsiveDesign">
              <SelectToTitle
                items={fiscalPositionObj}
                label="Fiscal Position"
                value={fiscalPosition}
                onChange={(e) => setFiscalPosition(e.target.value)}
              />
            </div>
            {/* <div className="inputText">
              <p>Fiscal Position</p>
              <select
                value={fiscalPosition}
                onChange={(e) => setFiscalPosition(e.target.value)}
              >
                <option value="">Choose any one</option>
                <option value="Immediat Payment">Immediate Payment</option>
              </select>
            </div> */}
          </div>
          <div className="Misc">
            <h3>Misc</h3>
            <div className="responsiveDesign">
              <InputFieldTitle
                label="Reference"
                type="text"
                value={refrence}
                onChange={(e) => setRefrence(e.target.value)}
              />
              <SelectToTitle
                items={industryObj}
                label="Industry"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              />
            </div>
            {/* <div className="inputText">
              <p>Reference</p>
              <input
                type="text"
                value={refrence}
                onChange={(e) => setRefrence(e.target.value)}
              />
            </div>
            <div className="inputText">
              <p>Industry</p>
              <select
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              >
                <option value="">Choose any one</option>
                <option value="Administrative">Administrative</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Construction">Construction</option>
                <option value="Education">Education</option>

                <option value="Entertainment">Entertainment</option>
                <option value="Extraterritorial">Extraterritorial</option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesPurchase;
