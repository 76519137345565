import React from "react";
import styles from "./index.module.scss";

const SelectToTitle = ({ id, label, items, onChange, value }) => {
  // console.log(items);
  return (
    <div className={`${styles.formGroup} ${styles.field}`}>
      <select onChange={(e) => onChange(e, id)} value={value} className={styles.formGroup__formField} placeholder={label} name={label} id={id} required>
        <option value="">Choose One Option</option>
        {items.map((item, ind) => {
          return (
            <option
              key={ind}
              value={item.id || item.PRODUCT_TYPE_ID || item.CATEGORY_ID || item.BRAND_ID || item.UNIT_OF_MEASUREMENT || item.PRODUCT_INCOME_NAME || item.EXPENSE_NAME || item.ID}>
              {item.name ||
                item.BRAND_NAME_ENGLISH ||
                item.CATEGORY_NAME ||
                item.PRODUCT_TYPE ||
                item.UNIT_OF_MEASUREMENT ||
                item.PRODUCT_INCOME_NAME ||
                item.EXPENSE_NAME ||
                item.UNIT_OF_MEASUREMENT}
            </option>
          );
        })}
      </select>
      <label htmlFor={id} className={styles.formGroup__formLabel}>
        {label}
      </label>
    </div>
  );
};

export default SelectToTitle;
