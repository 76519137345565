import Container from "../../shared/Container";
import React, { useState } from "react";
import bottomWave from "../../assets/bottomWave.png";
import styles from "./index.module.scss";
import SectionTitle from "../../shared/SectionTitle";
import FaqAccordion from "../FaqAccordion";
import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const Startup = () => {
  const [show, setShow] = useState(1);
  const { ref, control } = useScroll();
  const variant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
    hidden: { opacity: 0, scale: 0.7 },
  };

  const faqs = [
    { id: 1, quetion: "Do I really have access to hundreds of apps and modules for a single price?", answer: "😊 Yes, you are not dreaming." },
    {
      id: 2,
      quetion: "Is multi-company or Studio available in the One App Free plan?",
      answer: "Yes, the free plan allows multiple companies. You can also choose Odoo Studio as the app for a free plan.",
    },
    { id: 3, quetion: "Do I really have access to hundreds of apps and modules for a single price?", answer: "😊 Yes, you are not dreaming." },
    { id: 4, quetion: "Do I really have access to hundreds of apps and modules for a single price?", answer: "😊 Yes, you are not dreaming." },
    {
      id: 5,
      quetion: "How do you define a paying user?",
      answer:
        "A paying user is usually an employee who has access to the Odoo backend to create, view, or edit documents. External users (such as clients or suppliers) using Odoo through the portal are not paying users. Examples of paying users: An employee who reports their expenses or timesheet A third-party accountant who uses Odoo to work on your finances Examples of free users: Customers and suppliers who have access to their invoices on the portal Visitors of your website who are placing eCommerce orders and tracking their deliveries",
    },
    { id: 6, quetion: "Do I really have access to hundreds of apps and modules for a single price?", answer: "😊 Yes, you are not dreaming." },
  ];

  const openAccordion = (id) => {
    setShow(id);
  };
  return (
    <div className={styles.cont}>
      <div className={styles.wave}>
        <img src={bottomWave} alt="bottomwave" className={styles.wave__img} />
      </div>
      <Container>
        <div className={styles.top}>
          <SectionTitle title="Any Questions?" desc="Connect With Us If You Have Any Questions" />
        </div>
        <div className={styles.content}>
          <p>
            If the answer to your question is not on this page, please contact our <span>Account Managers</span>
          </p>

          <motion.div ref={ref} variants={variant} initial="hidden" animate={control} className={styles.content__faqs}>
            {faqs.map((item) => {
              return <FaqAccordion show={show} key={item.id} id={item.id} question={item.quetion} answer={item.answer} openAccordion={() => openAccordion(item.id)} />;
            })}
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default Startup;
