import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "userInterface",
  initialState: { isResgister: false, isLogged: false },
  reducers: {
    onOpenRegistration: (state) => {
      state.isResgister = !state.isResgister;
    },
    onUserIsLogged: (state, action) => {
      state.isLogged = action.payload;
    },
  },
});

export const uiSliceAction = uiSlice.actions;
export default uiSlice.reducer;
