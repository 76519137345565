import React from "react";
import styles from "./index.module.scss";

const Loader = (props) => {
  return (
    <div className={styles.loader}>
      <div style={{ backgroundColor: props.bg }}></div>
      <div style={{ backgroundColor: props.bg }}></div>
      <div style={{ backgroundColor: props.bg }}></div>
      <div style={{ backgroundColor: props.bg }}></div>
    </div>
  );
};

export default Loader;
