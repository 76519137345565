import React, { useState } from "react";
import Modal from "..";
import InputFieldTitle from "../../shared/InputFieldTitle";
import styles from "./index.module.scss";
import SelectToTitle from "../../shared/SelectToTitle";
import CloseIcon from "../../components/CloseIcon";
import DragAndDrop from "../../shared/DragAndDrop";
import Button from "../../shared/Button";
import { useDispatch } from "react-redux";
import { uiSliceAction } from "../../redux/reducers/ui";
import { onRegister } from "../../services/server";
import toast from "react-hot-toast";

const compnaySizes = [
  { id: 1, name: "<5" },
  { id: 2, name: "5-10" },
  { id: 3, name: "10-25" },
  { id: 4, name: "25-50" },
  { id: 5, name: "50-75" },
  { id: 6, name: "75-100" },
  { id: 7, name: " >100" },
];

const primaryInterest = [
  { id: 1, name: "Packing" },
  { id: 2, name: "Inventory" },
  { id: 3, name: "Expenses" },
  { id: 4, name: "POS" },
];

const countries = [
  { id: 1, name: "India" },
  { id: 2, name: "Saudi Arabia" },
];

const companyType = [
  { id: 1, name: "IT" },
  { id: 2, name: "Grocery" },
];

const Register = ({ selectedPack }) => {
  const dispatch = useDispatch();
  const [rData, setRData] = useState({
    Create_by: "",
    Company_name: "",
    Company_size: "",
    Company_type: "",
    Primary_interest: "",
    User_email: "",
    User_mob: "",
    Country: "",
    Address: "",
  });
  const [logo, setLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const formData = new FormData();

  const onCloseRegistration = () => {
    dispatch(uiSliceAction.onOpenRegistration());
    document.body.style.overflowY = "scroll";
    // document.body.style.overflowX = "hidden";
  };

  const onGetRegisData = (e, id) => {
    setRData((prev) => ({ ...prev, [id]: e.target.value }));
  };

  const onSaveCompany = async () => {
    setIsLoading(true);
    formData.append("Company_logo", logo);
    formData.append("Company_name", rData.Company_name);
    formData.append("User_mob", rData.User_mob);
    formData.append("User_email", rData.User_email);
    formData.append("Address", rData.Address);
    formData.append("Country", rData.Country);
    formData.append("Company_size", rData.Company_size);
    formData.append("Primary_interest", rData.Primary_interest);
    formData.append("Company_type", rData.Company_type);
    formData.append("Credit_limit", selectedPack.price ? selectedPack.price : "0");
    formData.append("Create_by", rData.Create_by);
    formData.append("Subscription_type", selectedPack.packageId ? selectedPack.packageId : "4");
    formData.append("Create_by", rData.Create_by);
    selectedPack.length > 0 && formData.append("selected_apps", selectedPack);

    if (rData.Create_by === "" || rData.Create_by.length < 3) {
      toast.error("Enter A Valid Full Name");
      setIsLoading(false);
    } else if (rData.Company_name === "") {
      toast.error("Enter A Valid Company Name");
      setIsLoading(false);
    } else if (rData.User_mob.length !== 10) {
      toast.error("Enter A Valid Mobile Number");
      setIsLoading(false);
    } else if (rData.Primary_interest === "") {
      toast.error("Please Select Primary Interest");
      setIsLoading(false);
    } else if (rData.Company_type === "") {
      toast.error("Please Select Company Type");
      setIsLoading(false);
    } else if (rData.Company_size === "") {
      toast.error("Please Select Company Size");
      setIsLoading(false);
    } else if (!rData.User_email.includes("@") || !rData.User_email.includes(".")) {
      toast.error("Enter A Valid Email Id");
      setIsLoading(false);
    } else if (rData.Address === "") {
      toast.error("Enter A Valid Full Address");
      setIsLoading(false);
    } else if (rData.Country === "") {
      toast.error("Please Select Country");
      setIsLoading(false);
    } else if (logo === "") {
      toast.error("Please Upload Company Logo");
      setIsLoading(false);
    } else {
      const response = await onRegister(formData);

      if (response.data.status) {
        dispatch(uiSliceAction.onOpenRegistration());
        toast.success(response.data.message);
        setIsLoading(false);
        document.body.style.overflowY = "scroll";
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal>
      <div className={styles.phone}>
        <CloseIcon onClick={onCloseRegistration} />
      </div>
      <div className={styles.register}>
        <h1>Xstore Suite - Get Started</h1>
        <p>Instant Access & Simple To Use</p>
        <div className={styles.register__form}>
          <InputFieldTitle onChange={onGetRegisData} label="Full Name" id="Create_by" type="text" />
          <InputFieldTitle onChange={onGetRegisData} label="Company Name" id="Company_name" type="text" />
          <InputFieldTitle onChange={onGetRegisData} label="Mobile Number" id="User_mob" type="number" />

          <SelectToTitle onChange={onGetRegisData} label="Primary Interest" id="Primary_interest" items={primaryInterest} />
          <SelectToTitle onChange={onGetRegisData} label="Company Type" id="Company_type" items={companyType} />
          <SelectToTitle onChange={onGetRegisData} label="Company Size" id="Company_size" items={compnaySizes} />
        </div>
        <div className={styles.register__bot}>
          <div className={styles.register__bot__left}>
            <InputFieldTitle onChange={onGetRegisData} label="Email" id="User_email" type="email" />
            <InputFieldTitle onChange={onGetRegisData} label="Address" id="Address" type="text" />
            <SelectToTitle onChange={onGetRegisData} label="Country" id="Country" items={countries} />
          </div>
          <DragAndDrop setLogo={setLogo} />
        </div>
        <div className={styles.close}>
          <CloseIcon onClick={onCloseRegistration} />
        </div>
        <div className={styles.register__btn}>
          <Button name="Continue" onClick={onSaveCompany} loading={isLoading} />
          {/* <Button name="Close" onClick={onSaveCompany} /> */}
        </div>
        <p>
          By clicking on Continue, you accept our <span>Subscription Agreement</span> and <span>Privacy Policy</span>
        </p>
      </div>
    </Modal>
  );
};

export default Register;
