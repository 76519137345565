import React from "react";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useScroll from "../../hooks/useScroll";

const ServiceItem = (props) => {
  const { control, ref } = useScroll();
  const variant = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
    hidden: { opacity: 0, x: -50 },
  };

  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} className={styles.serviceItem}>
      <div onClick={props.onClick} style={{ backgroundColor: props.bgColor }} className={`${styles.serviceItem__item} ${props.hovClass}`}>
        <img src={props.src} alt={props.title} height={80} width={80} />
      </div>
      <h4 className={styles.serviceItem__title}>{props.title}</h4>
    </motion.div>
  );
};

export default ServiceItem;
