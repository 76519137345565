import styles from "./index.module.scss";
const Tabs = ({ tabs, onTabClicked, tabClicked }) => {
  return (
    <div className={styles.tabs}>
      {tabs.map((item) => {
        return (
          <div
            onClick={() => onTabClicked(item.name)}
            key={item.id}
            style={{ flex: tabClicked === item.name ? "1 0 200px" : "" }}
            className={`${styles.tabs__tab} ${tabClicked === item.name ? styles.active : ""}`}>
            <p style={{ color: tabClicked === item.name ? "#fff" : "" }}>{item.name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
