import React from "react";
import styles from "./index.module.scss";

const Container = (props) => {
  return (
    <section style={{ backgroundColor: props.bg }} className={styles.section}>
      <div className={styles.section__container}>{props.children}</div>
    </section>
  );
};

export default Container;
