"use client";
import React, { useState, useRef, useEffect } from "react";
import inventory from "../../assets/newicons/inventory.svg";
import purchase from "../../assets/newicons/purchase.svg";
import accounting from "../../assets/newicons/accounting.svg";
import employees from "../../assets/newicons/employee.svg";
import sales from "../../assets/newicons/sales.svg";
import expenses from "../../assets/newicons/expenses.svg";
import crm from "../../assets/newicons/crm.svg";
import hrm from "../../assets/newicons/hrm.svg";
import Container from "../../shared/Container";
import styles from "./index.module.scss";
import SingleApp from "../SingleApp";
import Register from "../../modals/Register";
import { useDispatch, useSelector } from "react-redux";
import { uiSliceAction } from "../../redux/reducers/ui";

const ChooseApp = () => {
  const apps = [
    { id: 1, name: "Inventory", src: inventory },
    { id: 2, name: "purchase", src: purchase },
    { id: 3, name: "accounting", src: accounting },
    { id: 4, name: "sales", src: sales },
    { id: 5, name: "expenses", src: expenses },
    { id: 6, name: "employees", src: employees },
    { id: 7, name: "CRM", src: crm },
    { id: 8, name: "HRM", src: hrm },
  ];
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedAppsId, setSelectedAppsId] = useState([]);
  const [clientW, setClientW] = useState(0);
  const isRegisterOpen = useSelector((state) => state.ui.isResgister);

  const onSelectPlatform = (e, item) => {
    const existingApp = selectedApps.find((itm) => itm.id === item.id);
    const existingAppId = selectedAppsId.find((itm) => itm.id === item.id);

    if (existingApp || existingAppId) {
      const filteredData = selectedApps.filter((itm) => itm.id !== item.id);
      const filteredIdData = selectedAppsId.filter((itm) => itm !== item.id);
      setSelectedApps(filteredData);
      setSelectedAppsId(filteredIdData);
    } else {
      setSelectedApps((prev) => [...prev, item]);
      setSelectedAppsId((prev) => [...prev, item.id]);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setClientW(divRef.current.clientWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openRegisterModal = () => {
    dispatch(uiSliceAction.onOpenRegistration());
    document.body.style.overflow = "hidden";
  };

  const topVar = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.9 } },
    hidden: { opacity: 0, y: 200 },
  };

  const botVar = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
    hidden: { opacity: 0, y: -200 },
  };

  return (
    <>
      {isRegisterOpen && <Register selectedPack={selectedApps} />}
      <div ref={divRef} className={styles.chooseApp}>
        <Container>
          <div
            style={{
              display: clientW > 768 ? (selectedApps.length > 0 ? "flex" : "") : "",
            }}
            className={styles.chooseApp__content}>
            <div
              className={styles.chooseApp__content__left}
              style={{ display: clientW > 768 && clientW <= 1200 ? (selectedApps.length > 0 ? "flex" : "") : "", flexWrap: clientW > 768 && clientW <= 1200 ? "wrap" : "nowrap" }}>
              {apps.map((item, ind) => {
                return (
                  <SingleApp
                    selectedAppsId={selectedAppsId.length <= 2 ? selectedAppsId : []}
                    key={item.id}
                    id={item.id}
                    src={item.src}
                    title={item.name}
                    variant={(ind + 1) % 2 === 0 ? topVar : botVar}
                    onSelectPlatform={(e) => onSelectPlatform(e, item)}
                  />
                );
              })}
            </div>
            {selectedApps.length > 0 && (
              <div className={styles.chooseApp__content__right}>
                <div className={`${styles.chooseApp__content__right__select} ${clientW < 768 && styles.fixedPos}`}>
                  <h4>
                    {selectedApps.length} {selectedApps.length > 1 ? "Apps" : "App"} Selected
                  </h4>
                  {selectedApps?.map((item) => {
                    return (
                      <div key={item.id} className={styles.chooseApp__content__right__select__apps}>
                        <img src={item.src} alt={item.name} height={30} width={30} />
                        <p>{item.name}</p>
                      </div>
                    );
                  })}
                  <div className={styles.chooseApp__content__right__select__terms}>
                    <p>
                      <span>15 days free trial, </span>no credit card required.
                    </p>
                  </div>
                  <button onClick={openRegisterModal}>Continue</button>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default ChooseApp;
