import React from "react";
import bg from "../../assets/inventory.webp";
import Banner from "../../components/Banner";
import Testimonials from "../../components/Testimonials";
import AboutFuture from "../../components/AboutFuture";
import OurMission from "../../components/OurMission";

const AboutUs = () => {
  return (
    <>
      <Banner banner={bg} title="About Us" desc="We are providing solutions for your businesses." />
      <AboutFuture />
      <OurMission />
      <Testimonials />
    </>
  );
};

export default AboutUs;
