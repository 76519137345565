import React from "react";
import styles from "./index.module.scss";
import Loader from "../Loader";

const Button = (props) => {
  return (
    <button onClick={props.onClick} className={styles.button}>
      {props.loading ? <Loader /> : props.name}
    </button>
  );
};

export default Button;
