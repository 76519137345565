import axios from "axios";

const BASE_URL = "https://admin.xstoresuit.com/api/";

const serverStart = (url, method, data) => {
  const getConfig = {
    url: url,
    method: method,
    data: data,
  };

  if (method === "POST") {
    return axios(getConfig);
  } else {
    return axios(getConfig);
  }
};

export const onRegister = (params) => {
  return serverStart(BASE_URL + "Company/Create", "POST", params);
};
export const onContactUs = (params) => {
  return serverStart(BASE_URL + "ContactUs/Add", "POST", params);
};
export const onGetPrice = () => {
  return serverStart(BASE_URL + "packages/GetAll", "GET", null);
};
