import React from "react";

import Container from "../../shared/Container";
import ServiceItem from "../ServiceItem";
import inventory from "../../assets/newicons/inventory.svg";
import purchase from "../../assets/newicons/purchase.svg";
import accounting from "../../assets/newicons/accounting.svg";
import employees from "../../assets/newicons/employee.svg";
import sales from "../../assets/newicons/sales.svg";
import expenses from "../../assets/newicons/expenses.svg";
import crm from "../../assets/newicons/crm.svg";
import hrm from "../../assets/newicons/hrm.svg";
import styles from "./index.module.scss";
import SectionTitle from "../../shared/SectionTitle";

const AllServices = () => {
  return (
    <Container>
      <div className={styles.allServices1}>
        <SectionTitle title="All Services" desc="All Your Needs In One Platform" />
      </div>
      <div className={styles.allServices}>
        <ServiceItem src={inventory} title="Inventory" />
        <ServiceItem src={purchase} title="purchase" />
        <ServiceItem src={accounting} title="accounting" />
        <ServiceItem src={sales} title="sales" />
        <ServiceItem src={expenses} title="expenses" />
        <ServiceItem src={employees} title="employees" />
        <ServiceItem src={crm} title="CRM" />
        <ServiceItem src={hrm} title="HRM" />
      </div>
    </Container>
  );
};

export default AllServices;
