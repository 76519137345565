import React from "react";
import styles from "./index.module.scss";
import FooterTitle from "../../FooterTitle";
import { motion } from "framer-motion";
import useScroll from "../../../hooks/useScroll";

const Newsletter = () => {
  const { ref, control } = useScroll();
  const variant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    hidden: { opacity: 0, y: 100 },
  };
  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} className={styles.newsletter}>
      <FooterTitle title="Subscribe newsletter" />
      <p className={styles.newsletter__desc}>I’m okay with getting emails and having that tracked to improve my experience</p>
      <div className={styles.newsletter__field}>
        <input type="email" name="email" placeholder="Your Email Address" />
        <button>Subscribe</button>
      </div>
    </motion.div>
  );
};

export default Newsletter;
