import React from "react";
import styles from "./index.module.scss";
import FooterTitle from "../../FooterTitle";
import Lists from "../Lists";
import useScroll from "../../../hooks/useScroll";
import { motion } from "framer-motion";

const ContactUs = ({ width }) => {
  const { ref, control } = useScroll();
  const variant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, y: 100 },
  };
  const items = [
    { id: 1, name: "support@example.com" },
    { id: 2, name: "+258 (549) 2158 3215" },
    { id: 3, name: "2589 Dorland Street Luke INUA Berlin, Germany" },
  ];
  return (
    <motion.div ref={ref} variants={variant} initial="hidden" animate={control} style={{ width: width }} className={styles.contactUs}>
      <FooterTitle title="Contact Us" />
      <Lists items={items} />
    </motion.div>
  );
};

export default ContactUs;
